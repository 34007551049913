<div class="h-screen w-screen flex flex-1 items-center justify-center bg-white relative">
  <div class="max-w-md overflow-y-auto px-4 sm:w-[500px]">
    <div class="flex items-center justify-center">
      <img class="w-28 xs:w-32 sm:w-36 md:w-40 lg:w-44" src="/assets/logo.png" alt="Wedding Hub Logo">
    </div>
    <div class="my-10 space-y-6">
      <div class="text-center">
        <h2 class="mb-1 text-3xl font-semibold text-gray-700">
          <span>{{appName}} Welcome</span><span class="text-primary-500">!</span>
        </h2>
        <p *ngIf="!message" class="text-sm text-gray-400">Enter your credential to access your account.</p>
        <p *ngIf="message" class="text-sm text-red-400">{{message}}</p>
      </div>

      <div [class.hidden]="callback" class="flex flex-col p-6 space-y-3 text-left">
        <span class="text-2xl text-red-500 text-center">Token Expired!</span>
      </div>
      <div [class.hidden]="!callback" class="flex flex-col p-6 space-y-3 text-left">

        <mat-form-field appearance="outline">
          <mat-label>Phone</mat-label>
          <input matInput [formControl]="phone">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput [formControl]="password" [type]="showPassword ? 'text' : 'password'"
                 placeholder="Password">
          <mat-icon matSuffix class="cursor-pointer"
                    (click)="showPassword = !showPassword">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-hint class="text-gray-400">Must more than 8 digits</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Confirm Password</mat-label>
          <input matInput [formControl]="confirmPassword" [type]="showPassword ? 'text' : 'password'"
                 placeholder="Confirm Password">
          <mat-icon matSuffix class="cursor-pointer"
                    (click)="showPassword = !showPassword">{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
          <mat-hint class="text-gray-400">Must more than 8 digits</mat-hint>
        </mat-form-field>

        <button (click)="onConfirmPassword()" class="w-full" mat-flat-button color="primary">Sing In</button>
        <button mat-stroked-button (click)="onCancel()">Cancel</button>
      </div>
    </div>

  </div>
  <div [class.loading]="loading"></div>
</div>
