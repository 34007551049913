export const environment: any = {
  firebase: {
    projectId: 'event-hub-807bc',
    appId: '1:701407406133:web:3827091488b5f2222ca97c',
    databaseURL: 'https://event-hub-807bc-default-rtdb.asia-southeast1.firebasedatabase.app',
    storageBucket: 'event-hub-807bc.appspot.com',
    locationId: 'asia-southeast1',
    apiKey: 'AIzaSyB2vSbRRNqd8Jj9LtUa7mzkfBN4Sp9Wkug',
    authDomain: 'event-hub-807bc.firebaseapp.com',
    messagingSenderId: '701407406133',
    measurementId: 'G-5WBMCQB7CE',
  },
  name: 'Dev Wedding Hub',
  secret: '74541234567890123456789012345678',
  url: 'https://authentication-dev.eventhub.one',
};
