<div class="h-screen w-screen flex flex-1 items-center justify-center bg-white relative">
  <div class="max-w-md overflow-y-auto px-4 sm:w-[500px]">
    <div class="flex items-center justify-center">
      <img class="w-28 xs:w-32 sm:w-36 md:w-40 lg:w-44" src="/assets/logo.png" alt="Wedding Hub Logo">
    </div>
    <div class="my-10 space-y-6">
      <div class="text-center">
        <h2 class="mb-1 text-3xl font-semibold text-gray-700">
          <span>{{appName}} Welcome</span><span class="text-primary-500">!</span>
        </h2>
        <p *ngIf="!message" class="text-sm text-gray-400">Enter your credential to access your account.</p>
        <p *ngIf="message" class="text-sm text-red-400">{{message}}</p>
      </div>

      <div [class.hidden]="callback" class="flex flex-col p-6 space-y-3 text-left">
        <span class="text-2xl text-red-500 text-center">Token Expired!</span>
      </div>
      <div [class.hidden]="!callback" class="flex flex-col p-6 space-y-3 text-left">
        <mat-form-field appearance="outline">
          <mat-label>Phone</mat-label>
          <span matTextPrefix>+855&nbsp;</span>
          <input matInput type="number" [formControl]="phone" placeholder="12-123-456">
          <mat-icon matSuffix class="cursor-pointer" *ngIf="phone.enabled" (click)="onPhoneVerify()">
            arrow_circle_right
          </mat-icon>
        </mat-form-field>
        <div [hidden]="!showVerify" id="recaptcha-container"></div>
      </div>
    </div>

  </div>
  <div [class.loading]="loading"></div>
</div>
