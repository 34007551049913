import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {ReactiveFormsModule} from "@angular/forms";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {AngularFireModule} from "@angular/fire/compat";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HeaderInterceptor} from "./http.interceptor";
import {RouterModule} from "@angular/router";
import {SuccessComponent} from './success/success.component';
import {AuthenComponent} from './authen/authen.component';
import {PasswordComponent} from "./password/password.component";
import {AppService} from "./app.service";
import {VerifyOtpComponent} from "./verify-otp/verify-otp.component";
import {SetPasswordComponent} from "./set-password/set-password.component";
import {FailedComponent} from "./failed/failed.component";

@NgModule({
  declarations: [
    AppComponent,
    SuccessComponent,
    AuthenComponent,
    PasswordComponent,
    VerifyOtpComponent,
    SetPasswordComponent,
    FailedComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      {
        path: '',
        component: AuthenComponent
      },
      {
        path: 'password',
        component: PasswordComponent
      },
      {
        path: 'verify-otp',
        component: VerifyOtpComponent,
      },
      {
        path: 'set-password',
        component: SetPasswordComponent
      },
      {
        path: 'success',
        component: SuccessComponent
      },
      {
        path: 'failed',
        component: FailedComponent
      }
    ])
  ],
  providers: [
    AppService,
    {provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
