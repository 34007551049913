import {Component, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";
import {FormControl, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {take} from "rxjs";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import firebase from "firebase/compat/app";
import {AppService} from "../app.service";

@Component({
  selector: 'app-authen',
  templateUrl: './authen.component.html'
})
export class AuthenComponent implements OnInit {
  appName: string = environment.name;
  message: string | null = null;
  showVerify: boolean = false;
  phone = new FormControl('', [Validators.required]);
  loading: boolean = false;

  constructor(
    private _afAuth: AngularFireAuth,
    private _http: HttpClient,
    private _service: AppService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
  }

  get callback(): string | undefined {
    return this._service.callback;
  }

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe((params) => {
      this._service.callback = params['callback'];
      this._service.uuid = params['uuid'];
    });
  }

  async onPhoneVerify(): Promise<void> {
    if (this.phone.invalid) {
      this.phone.markAsTouched();
      return;
    }
    this.loading = true;
    this.phone.disable();
    this._http.get<any>(`/WebUser/${this.phone.value}`)
      .pipe(take(1))
      .subscribe(
        {
          next: async (res) => {
            this.loading = false;
            this._service.type = res.data;
            this._service.phone = this.phone.value!;
            if (res.data == 'password') {
              await this._router.navigateByUrl(`password?callback=${this.callback}`);
              return;
            }
            await this._onFirebaseAuth();
            await this._router.navigateByUrl(`verify-otp?callback=${this.callback}`);
          }, error: (error) => {
            this.loading = false;
            this.message = error.statusText;
            this.phone.enable();
          }
        });
  }

  private async _onFirebaseAuth(): Promise<void> {
    try {
      this.showVerify = true;
      const applicationVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
      this._service.confirmationResult = await this._afAuth.signInWithPhoneNumber(`+855${this.phone.value}`, applicationVerifier);
      this.showVerify = false;
    } catch (e: any) {
      this._service.confirmationResult = undefined;
      this.showVerify = false;
      this.message = e.message;
    }
  }

}
