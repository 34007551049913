import { Component } from '@angular/core';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-failed',
  templateUrl: './failed.component.html'
})
export class FailedComponent {
  appName: string = environment.name;
}
