import {Injectable} from '@angular/core';
import firebase from "firebase/compat/app";

@Injectable()
export class AppService {
  callback: string | undefined;
  uuid: string | undefined;
  phone: string | undefined;
  confirmationResult: firebase.auth.ConfirmationResult | undefined;
  type: 'set-password' | 'password' | 'otp' | undefined;
  token: string | undefined;
}
