import {Component, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";
import {FormControl, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AppService} from "../app.service";
import {take} from "rxjs";

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html'
})
export class VerifyOtpComponent implements OnInit {
  appName: string = environment.name;
  message: string | null = null;
  phone = new FormControl({value: '', disabled: true});
  otp = new FormControl('', [Validators.required]);
  loading: boolean = false;

  constructor(
    private _afAuth: AngularFireAuth,
    private _http: HttpClient,
    private _service: AppService,
    private _router: Router
  ) {
  }

  get callback(): string | undefined {
    return this._service.callback;
  }

  ngOnInit(): void {
    this.phone.setValue(`+855${this._service.phone}`);
  }

  async onConfirmOtp() {
    if (this.otp.invalid) {
      this.otp.markAsTouched();
      return;
    }
    this.loading = true;
    if (this._service.confirmationResult == null) {
      await this.onCancel();
    }
    this._service.confirmationResult?.confirm(this.otp.value!)
      .then(async (auth) => {
        this._service.token = await auth.user?.getIdToken();
        this.loading = false;
        if (this._service.type == 'otp') {
          await this._onRegister();
        }
        await this._router.navigateByUrl(`set-password?callback=${this.callback}`);
      }, (error) => {
        this.loading = false;
        this.message = error.message;
      });
  }

  private _onRegister() {
    const body = {
      phoneNumber: this.phone.value,
      token: this._service.token,
    };

    this._http.post<any>(`/WebUser`, body)
      .pipe(take(1))
      .subscribe(
        {
          next: async (_) => {
            this.loading = false;
          }, error: async (error) => {
            this.loading = false;
            this.message = error.statusText;
            await this._router.navigateByUrl(`failed?callback=${this.callback}`);
          }
        });
  }

  async onCancel() {
    await this._router.navigateByUrl(`/?callback=${this.callback}`);
  }
}
