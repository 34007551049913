import {Component, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";
import {FormControl, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {take} from "rxjs";
import {AppService} from "../app.service";

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html'
})
export class PasswordComponent implements OnInit {
  appName: string = environment.name;
  showPassword: boolean = false;
  message: string | null = null;
  password = new FormControl(
    '',
    [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(32)
    ]
  );
  loading: boolean = false;
  phone = new FormControl({value: '', disabled: true});

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _service: AppService,
  ) {
  }

  get callback(): string | undefined {
    return this._service.callback;
  }

  ngOnInit(): void {
    this.phone.setValue(`+855${this._service.phone}`);
  }

  async Cancel() {
    await this._router.navigateByUrl(`/?callback=${this.callback}`);
  }

  onConfirmPassword() {
    if (this.password.invalid) {
      this.password.markAsTouched();
      return;
    }
    this.loading = true;
    const req = {
      phoneNumber: this.phone.value?.toString(),
      password: this.password.value?.trim(),
      deviceId: this._service.uuid
    };
    this._http.post<any>(`/WebUser/Auth`, req)
      .pipe(take(1))
      .subscribe(
        {
          next: async (res) => {
            this.loading = false;
            if (res.status == 'E') {
              this.message = res.message;
              return;
            }
            await this._router.navigateByUrl(`success?token=${res.data.token}&refreshToken=${res.data.refreshToken}`);
          }, error: (error) => {
            this.loading = false;
            this.message = error.statusText;
            this.phone.enable();
          }
        });
  }
}
