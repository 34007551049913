import { Component } from '@angular/core';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html'
})
export class SuccessComponent {
  appName: string = environment.name;
}
